<template>
    <!-- 合同资信评价 -->
    <div class="contract-evaluation">
        <div class="contract-evaluation-box">
            <div class="width-half">
                <p>期次</p>
                <p>
                    <el-select
                        @change="changeSelect"
                        v-model="periodsValue"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </p>
            </div>
            <div class="width-half">
                <p>合同编号</p>
                <p
                    class="color-black"
                    v-if="!selectWindow.contract_number"
                    @click="awakenWindow"
                    style="cursor:pointer"
                >
                    【点击选择合同】
                </p>
                <p
                    v-else
                    style="cursor:pointer"
                    @click="awakenWindow"
                >
                    {{ selectWindow.contract_number }}
                </p>
            </div>
            <div class="width-half">
                <p>客户名称</p>
                <p>{{ selectWindow.client_name }}</p>
            </div>
            <div class="width-half">
                <p>工程名称</p>
                <p>{{ selectWindow.engineering_name }}</p>
            </div>
            <div class="width-half">
                <p>预计方量</p>
                <p>{{ getCreditInitData.projected_capacity }}</p>
            </div>
            <div class="width-half">
                <p>发货总方量</p>
                <p>{{ getCreditInitData.delivery_total_quantity }}</p>
            </div>
            <div class="width-half">
                <p>累计实收</p>
                <p>{{ getCreditInitData.aggregate_official_receipt }}</p>
            </div>
            <div class="width-half">
                <p>应收账款余额</p>
                <p>{{ getCreditInitData.accounts_receivable_balance }}</p>
            </div>
            <div class="width-half">
                <p>综合评分</p>
                <p>
                    <el-input class="zhpf" readonly v-model="totalScoreTwo"></el-input>
                </p>
            </div>
            <div class="width-half">
                <p>资信评价时间</p>
                <p>{{ nowTime }}</p>
            </div>
            <div class="width-half">
                <p>资信评价部门</p>
                <p>{{ deptName }}</p>
            </div>
            <div class="width-half">
                <p>资信评价人</p>
                <p>{{ userName }}</p>
            </div>
            <div class="width-all">
                <p>备注</p>
                <el-input :disabled="viewOnly" v-model="beizhu" placeholder="请输入"></el-input>
            </div>
        </div>
        <div class="contract-evaluation-message" v-if="getCreditInitItem.length > 0">
            <div class="message-header">
                <h1>评价维度</h1>
                <p>本项评分</p>
                <p>评价规则</p>
                <p>综合评分权重</p>
            </div>
            <div class="message-content">
                <div class="message-content-box" v-for="(item, index) in getCreditInitItem" :key="index">
                    <!-- 评价维度 -->
                    <h1>{{ item.name }}</h1>
                    <!-- 本项评分 -->
                    <p>
                        <el-input
                            @change="calculateScore(item)"
                            v-model="item.item_score"
                            :disabled="[0, 1, 2, 5].indexOf(index) > -1 || viewOnly"
                        ></el-input>
                    </p>
                    <!-- 评价规则 -->
                    <p>
                        <span @click="setEvaluateRule(item)">查看</span>
                    </p>
                    <!-- 综合评分权重 -->
                    <p>
                        <el-input
                            disabled
                            v-model="item.weight"
                        ></el-input>%
                    </p>
                </div>
                <div class="message-content-box">
                    <h1>合计</h1>
                    <p></p>
                    <p></p>
                    <p>
                        <el-input :disabled="viewOnly" readonly v-model="totalScore"></el-input>%
                    </p>
                </div>
            </div>
        </div>
        <div class="message-bottom-button" v-if="!viewOnly">
            <el-button @click="saveMessage" type="primary">
                保存
            </el-button>
            <el-button @click="handleCancel">
                取消
            </el-button>
        </div>
        <evaluateWindow
            v-if="showEvaluateWindow"
            :current-data="currentData"
            :run-time-date="runTimeDate"
            :view-only="true"
            @closeWindow="closeWindow"
        ></evaluateWindow>
    </div>
</template>

<script>
import store from '@/store';
import utils from '@/utils/util';
import evaluateWindow from '../salesBusiness/tableOfEvaluationRules/index';
export default {
    data() {
        return {
            beizhu: '', // 备注
            totalScore: '', // 合计值
            currentData: {}, // 当前选中数据
            options: [],
            periodsValue: '',
            totalScoreTwo: '',
            getCreditInitItem: [],
            getCreditInitData: {},
            selectWindow: {},
            runTimeDate: {},
            nowTime: '',
            deptName: '',
            userName: '',
            viewOnly: false,
            showEvaluateWindow: false, // 是否显示评价弹窗
        };
    },
    props: {
        extr: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: {},
        },
    },
    components: {
        evaluateWindow,
    },
    mounted() {
        this.getCreditNo();
        this.nowTime = utils.currentTimeFormat('yyyy-MM-dd HH:mm:ss');
        this.deptName = this.$takeTokenParameters('DeptName');
        this.userName = this.$takeTokenParameters('Name');
        if (this.extr.QueryString === 'viewOnly') {
            this.viewOnly = true;
        }
        if (this.extr.creditNo) {
            this.periodsValue = this.extr.creditNo;
            this.selectWindow.contract_number = this.extr.contract_number;
            this.getCreditInit();
        }
    },
    methods: {
        closeWindow() {
            this.showEvaluateWindow = false;
        },
        changeSelect() {
            this.getCreditInit();
        },
        // 点击选择合同
        awakenWindow() {
            if (!this.viewOnly) {
                if (this.periodsValue) {
                    this.$toast(
                        {
                            title: true,
                            type: 'eject',
                            t_url: 'tableList/index',
                            extr: {
                                code: {
                                    TableCode: 'credit_choose_contract',
                                },
                                closeWindow: this.closeCallback,
                            },
                        }
                    );
                } else {
                    this.$message.warning('请先选择期次');
                }
            }
        },
        // 弹窗数据回调
        closeCallback(data) {
            // contract_number 合同编号
            // client_name 客户名称
            // engineering_name 工程名称
            this.selectWindow = data;
            this.getCreditInit();
        },
        handleCancel() {
            this.$parent.hide();
        },
        // 点击保存
        saveMessage() {
            if (this.totalScore !== 100) {
                this.$message.error('请输入正确的权重');
            } else {
                this.addCreditContract();
            }
        },
        // 保存
        addCreditContract() {
            const arr = this.getCreditInitItem.filter(item => item.item_score > 10 || item.item_score < 0);
            if (arr.length > 0) {
                this.$message.error('本项评分必须在0-10之间');
                return;
            }
            const obj = {
                contract_number: this.getCreditInitData.contract_number,
                credit_no: this.getCreditInitData.credit_no,
                remarks: this.beizhu,
                items: this.getCreditInitItem,
            };
            this.$axios
                .post('/interfaceApi/sale/credit_manager/credit_contract', obj)
                .then(() => {
                    if (this.extr.refreshTableFn) {
                        this.extr.refreshTableFn();
                    } else {
                        store.state.currentOpenList.forEach(item => {
                            item.tableObj.updateTable();
                        });
                    }
                    this.$message.success('保存成功');
                    this.$parent.hide();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 未配置时，获取初始化信息
        getCreditInit() {
            this.getCreditInitItem = [];
            const creditNo = this.periodsValue;
            const contractNumber = this.selectWindow.contract_number ? this.selectWindow.contract_number : '';
            this.$axios
                .get(`/interfaceApi/sale/credit_manager/credit_contract/${creditNo}/${contractNumber}`)
                .then(res => {
                    this.getCreditInitData = res;
                    this.getCreditInitItem = res.items;
                    let numbers = 0;
                    this.getCreditInitItem.map(item => {
                        numbers += Number(item.weight) * 1000;
                    });
                    this.totalScore = numbers / 1000;
                    this.beizhu = res.remarks;
                    this.selectWindow = {
                        contract_number: res.contract_number,
                        client_name: res.client_name,
                        engineering_name: res.engineering_name,
                    };

                    this.nowTime = res.Created;
                    this.deptName = res.dep_name;
                    this.userName = res.Creator;

                    this.calculateScore();
                    this.viewOnly = res.viewOnly;
                    if (this.extr.QueryString) {
                        if (this.extr.QueryString === 'viewOnly') {
                            this.viewOnly = true;
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 综合评分计算
        calculateScore(item) {
            if (item) {
                if (Number(item.item_score) > 10 || Number(item.item_score) < 0) {
                    this.$message.error('输入评分必须在0-10之间');
                }
            }
            let singleNumber = 0; // 单项值
            let weights = 0; // 权重
            let scores = 0; // 评分
            let ride = 0;
            this.getCreditInitItem.forEach(item => {
                weights = Number(item.weight) * 100;
                scores = Number(item.item_score) * 100;
                ride = scores * weights / 1000000;
                singleNumber += ride;
            });
            this.totalScoreTwo = singleNumber.toFixed(2);
        },
        // 拉取合同未配期数
        getCreditNo() {
            this.getCreditInitItem = [];
            this.$axios
                .get('/interfaceApi/sale/credit_config/get_credit_no?contractNumber=""')
                .then(res => {
                    this.options = res;
                    if (this.extr.creditNo) {
                        this.periodsValue = this.extr.creditNo;
                    } else {
                        this.periodsValue = res[0];
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 设置合同规则
        setEvaluateRule(item) {
            this.currentData = item;
            this.runTimeDate.AutoCodeValue = this.periodsValue;
            this.showEvaluateWindow = true;
        },
    },
};

</script>
<style lang='stylus'>
.contract-evaluation
    width 100%
    height 100%
    overflow auto
    padding .20rem
.contract-evaluation-box
    width 100%
    display flex
    flex-wrap: wrap;
    input::-webkit-input-placeholder
        font-size .16rem
    .width-half
        width 50%
        display flex
        height .45rem
        border-bottom 1px solid #ccc
        border-left 1px solid #ccc
        &:nth-child(1)
            border-top 1px solid #ccc
        &:nth-child(2)
            border-top 1px solid #ccc
        &:nth-child(even)
            border-right 1px solid #ccc
        p:first-child
            min-width 2.4rem
            max-width 2.4rem
            display flex
            align-items center
            font-size .16rem
            border-right 1px solid #ccc
            background #F6F8FC
            color #092d8e
            justify-content center
        p:last-child
            flex 1
            display flex
            font-size .16rem
            padding-left .2rem
            align-items center
            color #333
        .color-black
            color #ccc !important
        .zhpf input
            color red !important
        .el-input
            width 100% !important
            height .43rem
            .el-input__inner
                height .43rem
                border none
                font-size .16rem
                padding-left 0
        .el-select
            width 100%
    .width-all
        width 100%
        border-bottom 1px solid #ccc
        display flex
        height .45rem
        align-items center
        p:first-child
            width 2.41rem
            border-right 1px solid #ccc
            height 100%
            font-size .16rem
            background #F6F8FC
            display flex
            align-items center
            color #092d8e
            justify-content center
            border-left 1px solid #ccc
        .el-input
            border-right 1px solid #ccc
            flex 1
            height 100%
            input
                border none
                height .43rem
                font-size .16rem
.contract-evaluation-message
    width 100%
    h1
        border-left 1px solid #ccc
        width 2.41rem
        border-right 1px solid #ccc
        display flex
        align-items center
        justify-content center
        font-size .16rem
        height 100%
    p
        flex 1
        display flex
        height 100%
        align-items center
        justify-content center
        font-size .16rem
        border-right 1px solid #ccc
    .message-header
        height .45rem
        border-bottom 1px solid #ccc
        display flex
        h1, p
            background #EDF0F5
            color #092d8e
            font-weight 800
    .message-content
        display flex
        flex-direction column
        .message-content-box
            height .45rem
            border-bottom 1px solid #ccc
            display flex
            align-items center
            justify-content center
            p:nth-child(3)
                cursor pointer
                color #092d8e
                text-decoration underline
           .el-input
                width .6rem
                height 0.28rem
                input
                    padding 0
                    text-align center
                    font-size .16rem
                    height 0.28rem
.message-bottom-button
    width 100%
    display flex
    justify-content center
    margin-top .4rem
    padding-bottom .2rem
    position absolute
    bottom 0
    left 0
    width 100%
    button
        width 2.5rem
        &:last-child
            margin-left 1rem
</style>